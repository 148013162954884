import React, { useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pricings } from '../../utils/PriceList';
import { useConstructions } from '../../services/ConstructionsService';
import { License } from './License';
import { LicenseDetails } from '../../models';
import { updateLicense } from '../../services/LicenseService';
import { useUser } from '../../auth/authUserContext';
import { signInAuthProvider } from '../../auth/authProvider';
import { useHistory } from 'react-router';
// import { LoadingIcon } from '../Shared/Loading';

export const SelectLicense: React.FC = () => {
  const user = useUser();
  const history = useHistory();

  const [constructions, _, constructionsError] = useConstructions();

  const handleSelectLicense = (license: string, constructions: string[]) => {
    const details: LicenseDetails = {
      license,
      constructions,
    };

    history.push({ pathname: '/purchase-license', state: details });
    /*
    if (user.id) {
      updateLicense(user.id, request).then((success) => {
        if (success) {
          signInAuthProvider.logout();
        } else {
          console.log('failed to update license');
        }
      });
    }
  };
  */
  };

  if (constructionsError) {
    return (
      <Row>
        <Col xs={12} className="mt-3">
          <Alert color="danger">
            Något gick fel och du kan tyvärr inte välja licens just nu. Ladda om sidan eller lägg till licens
            senare.
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <h3>
        <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
        Välj licens
      </h3>

      <Row>
        {pricings.map((pricing, key) => (
          <Col key={key} md={4} xs={12}>
            <License pricing={pricing} constructions={constructions ?? []} onClick={handleSelectLicense} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
