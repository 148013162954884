import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Topbar from './Topbar';
import { Alert, Container, Spinner } from 'reactstrap';
import SideBar from './SideBar';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { useSession } from '../../useSession';
import { UserProvider } from '../../auth/authUserContext';
import { createPortal } from 'react-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Layout: React.FC = ({ children }) => {
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [user] = useSession();

  console.log({ user });

  /*useEffect(() => {
    if (user && !user?.hasBusinessConnection) {
      history.push('/onboarding');
    }
  }, [user, history]);*/

  if (!user) {
    return (
      <div className="d-flex justify-content-center" style={{ width: '100%', height: '100vh' }}>
        <div className="align-self-center text-center">
          <Spinner color="primary" style={{ width: '100px', height: '100px' }} />
          <h3 className="d-block mt-4">Laddar användarprofil</h3>
        </div>
      </div>
    );
  }

  return (
    <UserProvider value={user}>
      <div className="layout-with-sidebar">
        <SideBar isOpen={sideBarOpen} />
        <Container fluid className={classNames('content', { 'is-open': sideBarOpen })}>
          <div
            style={{
              height: '6px',
              position: 'fixed',
              left: 0,
              top: 0,
              right: 0,
              backgroundColor: '#f95149',
              zIndex: 1031,
            }}
          ></div>
          <Topbar sideBarOpen={sideBarOpen} toggleSideBar={() => setSideBarOpen(!sideBarOpen)} />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="pt-2"
          >
            {children}
          </motion.div>
        </Container>
      </div>
    </UserProvider>
  );
};

export default Layout;
