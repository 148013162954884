import { Notification } from './model';

export type Action = { type: 'show'; notification: Notification } | { type: 'hide'; id: string | number };
export type Dispatch = (action: Action) => void;
export type State = {
  notifications: Notification[];
};

export function notificationsReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'show': {
      if (state.notifications.some((x) => x.id === action.notification.id)) {
        return state;
      }
      return { notifications: [action.notification, ...state.notifications] };
    }
    case 'hide': {
      return {
        notifications: state.notifications.filter((x) => x.id != action.id),
      };
    }
  }
}
