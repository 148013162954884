import {
  faCheckSquare,
  faClock,
  faExternalLink,
  faHandHoldingSeedling,
  faInfo,
  faInfoCircle,
  faPencil,
} from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Media,
  Row,
} from 'reactstrap';
import logo from '../../assets/images/logo.png';
import ContentService from '../../services/ContentService';
import { Controller, useForm } from 'react-hook-form';
import { stringify } from 'node:querystring';
import { FindExistingAccount, RegisterAccount } from '../../services/AccountService';
import { FindExistingCompany } from '../../services/CompanyService';
import { toast } from 'react-toastify';
import { getLicenses } from '../../services/LicenseService';
import { License } from '../license/License';
import { useConstructions } from '../../services/ConstructionsService';
import { AsyncButton } from '../../components/Form/Button';

library.add(faCheckSquare);
library.add(faClock);
library.add(faExternalLink);
library.add(faHandHoldingSeedling);
library.add(faPencil);

interface Account {
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordVerify: string;
  type: 'private' | 'corporate';
  companyId: string;
  companyName: string;
  paymentMethod: 'none' | 'card' | 'invoice';
  license: string;
  licensedConstructions: string[];
  invoiceAddress: InvoiceAddress;
}

interface InvoiceAddress {
  line1: string;
  line2: string;
  postalCode: string;
  city: string;
  country: 'Sverige';
}

const OnboardingNext = () => {
  const [usps, setUsps] = useState(null);

  console.log('new onboarding');

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Account>({ mode: 'onBlur' });
  // payment method: 'none'
  // invoiceAddress: 'sverige'

  const accountType = watch('type');
  const paymentMethod = watch('paymentMethod');
  const license = watch('license');
  const selectedConstructions = watch('licensedConstructions');
  const [isLoading, setIsLoading] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [constructions] = useConstructions();

  const onCreateAccount = (account: Account) => {
    setIsLoading(true);
    RegisterAccount({
      companyId: account.companyId,
      companyName: account.companyName,
      emailAddress: account.emailAddress,
      firstName: account.firstName,
      invoiceAddress: {
        city: account.invoiceAddress?.city,
        country: 'Sverige',
        line1: account.invoiceAddress?.line1,
        postalCode: account.invoiceAddress?.postalCode,
        line2: account.invoiceAddress?.line2,
      },
      lastName: account.lastName,
      license: account.license,
      licensedConstructions: account.licensedConstructions,
      password: account.password,
      paymentMethod: account.paymentMethod,
      type: account.type,
    })
      .then((_) => {
        // toast
        toast.success(
          <p>
            Ditt konto har registrerats. Välkommen till Huskompassen! Du blir strax vidareskickad till
            inloggningssidan.
          </p>,
        );

        setTimeout(() => {
          window.location.assign('/');
        }, 1500);
      })
      .catch((_) => {
        // toast
        toast.error(<p>Något gick fel när ditt konto skulle registreras. Vänligen prova igen.</p>);
      })
      .finally(() => setIsLoading(false));
  };

  const onCreateFailed = (errors) => {
    console.log('errors', errors);
  };

  const updateSelectedConstructions = (construction: string) => {
    if (!selectedConstructions) {
      setValue('licensedConstructions', [construction]);
      return;
    }

    if (selectedConstructions.filter((x) => x == construction).length) {
      setValue(
        'licensedConstructions',
        selectedConstructions.filter((x) => x != construction),
      );
    } else {
      const newList = [...selectedConstructions, construction];
      if (newList.length > (selectedLicense?.allowedConstructionsCount ?? 0)) {
        newList.splice(0, 1);
      }

      setValue('licensedConstructions', newList);
    }
  };

  useEffect(() => {
    ContentService.get('cdn/stories/?starts_with=usps/', {}).then(({ data }) => {
      setUsps(data.stories);
    });

    getLicenses().then((l: any) => setLicenses(l));
  }, []);

  const ControllerPlus = ({ control, transform, name, defaultValue }) => (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={({ field }) => (
        <Input onChange={(e) => field.onChange(transform.output(e))} value={transform.input(field.value)} />
      )}
    />
  );

  return (
    <Container fluid>
      <Row style={{ margin: '40px' }}>
        <Col sm={{ size: 8, offset: 2 }}>
          <Row>
            <Col sm={7}>
              <h2>Välkommen till Huskompassen</h2>
              <p style={{ fontSize: '90%' }}>
                Kom igång med att räkna på material till ert nästa projekt. Det är snabbt och enkelt. Visste
                du att vi just nu erbjuder fem olika byggmoment att beräkna material för? Utbudet växer
                ständigt.
              </p>
              <p style={{ fontSize: '90%' }}>
                Har du redan ett konto?{' '}
                <strong>
                  <a href="/">Logga in</a>
                </strong>
                .
              </p>
              <form onSubmit={handleSubmit(onCreateAccount, onCreateFailed)}>
                <FormGroup>
                  <Label for="emailAddress">E-postadress</Label>
                  <Input
                    type="email"
                    placeholder="Ange din e-postadress"
                    {...register('emailAddress', {
                      required: 'E-postadress måste anges.',
                      min: 3,
                      validate: async (value) => {
                        if (await FindExistingAccount({ emailAddress: value }))
                          return 'Ett konto finns redan registrerat med denna e-postadress.';
                        else return true;
                      },
                    })}
                  />
                  {errors.emailAddress && <p style={{ color: 'red' }}>{errors.emailAddress.message}</p>}
                </FormGroup>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="firstName">Förnamn</Label>
                      <Input
                        placeholder="Ange ditt förnamn"
                        {...register('firstName', { required: 'Förnamn måste anges.', min: 3 })}
                      />
                      {errors.firstName && <p style={{ color: 'red' }}>{errors.firstName.message}</p>}
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="lastName">Efternamn</Label>
                      <Input
                        placeholder="Ange ditt efternamn"
                        {...register('lastName', { required: 'Efternamn måste anges.', min: 3 })}
                      />
                      {errors.lastName && <p style={{ color: 'red' }}>{errors.lastName.message}</p>}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="password">Lösenord</Label>
                  <Input type="password" {...register('password')} />
                  <ul className="onboarding-password">
                    <li>En liten bokstav</li>
                    <li>En stor bokstav</li>
                    <li>En siffra</li>
                    <li>Minst 8 tecken långt</li>
                  </ul>
                </FormGroup>
                <FormGroup>
                  <Label for="password">Bekräfta lösenord</Label>
                  <Input
                    type="password"
                    {...register('passwordVerify', {
                      validate: (value) => {
                        return value === getValues('password') || 'Lösenorden matchar inte varandra.';
                      },
                    })}
                  />
                  {errors.passwordVerify && <p style={{ color: 'red' }}>{errors.passwordVerify.message}</p>}
                </FormGroup>
                <FormGroup style={{ width: '100%' }}>
                  <Label for="accountType" style={{ marginBottom: '0' }}>
                    Typ av konto
                  </Label>
                  <Row>
                    <Col sm={12}>
                      <p style={{ fontSize: '90%' }}>Välj vilken typ av konto du registrerar.</p>
                      <ButtonGroup id="accountType" vertical style={{ width: '100%' }}>
                        <Button
                          color={accountType == 'private' ? 'dark' : 'light'}
                          onClick={() => setValue('type', 'private')}
                        >
                          Privatperson
                        </Button>
                        <Button
                          color={accountType == 'corporate' ? 'dark' : 'light'}
                          onClick={() => setValue('type', 'corporate')}
                        >
                          Företag
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </FormGroup>
                {accountType == 'corporate' ? (
                  <>
                    <FormGroup>
                      <Row>
                        <Col sm={6}>
                          <Label for="companyId">Organisationsnummer</Label>
                          <ControllerPlus
                            name="companyId"
                            defaultValue=""
                            control={control}
                            transform={{
                              input: (value) => {
                                if (value.length == 10 && !isNaN(value)) {
                                  return value.substring(0, 6) + '-' + value.substring(6);
                                }

                                return value;
                              },
                              output: (value) => value,
                            }}
                          />
                        </Col>
                        <Col sm={6}>
                          <Label for="companyName">Företagsnamn</Label>
                          <Input
                            type="text"
                            {...register('companyName', {
                              validate: async (value) => {
                                if (await FindExistingCompany({ companyName: value }))
                                  return 'Ett företag med detta namn finns redan registrerat.';
                                else return true;
                              },
                            })}
                          />
                          {errors.companyName && errors.companyName.message && (
                            <>
                              <p className="text-warning">{errors.companyName.message}</p>
                              <p>
                                <FontAwesomeIcon icon={faInfoCircle} className="text-success" /> Om du vill gå
                                med i företaget, registrera ditt konto som en privatperson och ansök sedan om
                                att ansluta dig till dem.
                              </p>
                            </>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup style={{ width: '100%' }}>
                      <Label for="payment" style={{ marginBottom: '0' }}>
                        Betalningsmetod
                      </Label>
                      <Row>
                        <Col sm={12}>
                          <p style={{ fontSize: '90%' }}>Kul att du vill registrera ditt företag med oss.</p>

                          <Button
                            color={paymentMethod == 'none' ? 'dark' : 'light'}
                            onClick={() => setValue('paymentMethod', 'none')}
                            className="onboarding-payment-alternative"
                            block
                            style={{ marginBottom: '20px' }}
                          >
                            Skapa konto för att utforska Huskompassen först
                          </Button>

                          <p style={{ fontSize: '90%' }}>
                            <strong>Tips!</strong> Väljer du att köpa licens redan nu är du snabbast igång.
                          </p>

                          <ButtonGroup id="payment" vertical style={{ width: '100%' }}>
                            <Button
                              color={paymentMethod == 'card' ? 'dark' : 'light'}
                              className="onboarding-payment-alternative btn-disabled"
                              style={{ cursor: 'default' }}
                            >
                              Kortbetalning
                              <Badge color="warning">Kommer snart!</Badge>
                            </Button>
                            <Button
                              color={paymentMethod == 'invoice' ? 'dark' : 'light'}
                              onClick={() => setValue('paymentMethod', 'invoice')}
                              className="onboarding-payment-alternative"
                            >
                              Faktura
                              <Badge color="warning">
                                <span style={{ textDecoration: 'line-through' }}>49 SEK</span> 0 SEK
                              </Badge>
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </Row>
                    </FormGroup>

                    {paymentMethod == 'invoice' && (
                      <>
                        <Row>
                          <h4>Faktureringsadress</h4>
                        </Row>
                        <FormGroup>
                          <Label for="invoiceAddress.line1">Adressrad 1</Label>
                          <Input type="text" {...register('invoiceAddress.line1')} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="invoiceAddress.line2">Adressrad 2</Label>
                          <Input type="text" {...register('invoiceAddress.line2')} />
                        </FormGroup>
                        <Row>
                          <Col sm={6}>
                            <FormGroup>
                              <Label for="invoiceAddress.postalCode">Postkod</Label>
                              <Input type="text" {...register('invoiceAddress.postalCode')} />
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <Label for="invoiceAddress.city">Stad</Label>
                              <Input type="text" {...register('invoiceAddress.city')} />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}

                    {(paymentMethod == 'card' || paymentMethod == 'invoice') && (
                      <>
                        <FormGroup style={{ width: '100%' }}>
                          <Label for="payment" style={{ marginBottom: '0' }}>
                            Licens
                          </Label>
                          <Row>
                            <Col sm={12}>
                              <p>Välj vilken licens du vill börja med.</p>
                              <ButtonGroup id="license" vertical style={{ width: '100%' }}>
                                {licenses.map((l) => (
                                  <Button
                                    key={l.sku}
                                    color={license == l.sku ? 'dark' : 'light'}
                                    className="onboarding-payment-alternative btn-disabled"
                                    style={{ cursor: 'default' }}
                                    onClick={() => {
                                      setValue('license', l.sku);
                                      setSelectedLicense(l);
                                      console.log(l.sku);
                                    }}
                                  >
                                    {l.name}
                                    <Badge color="warning">{l.price} SEK/år</Badge>
                                  </Button>
                                ))}
                              </ButtonGroup>
                              {selectedLicense != null && selectedLicense.sku != 'YearlySubscribers_Premium' && (
                                <>
                                  <p style={{ marginTop: '10px' }}>
                                    <strong>Välj vilka byggmoment som du vill kunna beräkna.</strong> Visste
                                    du att om du köper Premium så får du tillgång till alla nuvarande och
                                    kommande byggmoment?
                                  </p>
                                  <p>
                                    Du kan välja{' '}
                                    <strong>
                                      {selectedLicense?.allowedConstructionsCount > 0
                                        ? selectedLicense.allowedConstructionsCount
                                        : ''}{' '}
                                      byggmoment
                                    </strong>{' '}
                                    som du vill kunna beräkna.
                                  </p>
                                  <ButtonGroup id="construction" vertical style={{ width: '100%' }}>
                                    {constructions.map((c) => (
                                      <Button
                                        key={c.name}
                                        color={
                                          selectedConstructions &&
                                          selectedConstructions.filter((x) => x == c.name).length
                                            ? 'dark'
                                            : 'light'
                                        }
                                        className="onboarding-payment-alternative btn-disabled"
                                        style={{ cursor: 'default' }}
                                        onClick={() => updateSelectedConstructions(c.name)}
                                      >
                                        {c.title}
                                        {c.beta && <Badge color="warning">BETA</Badge>}
                                      </Button>
                                    ))}
                                  </ButtonGroup>
                                </>
                              )}
                            </Col>
                          </Row>
                        </FormGroup>
                      </>
                    )}
                  </>
                ) : null}
                <FormGroup>
                  <p style={{ textAlign: 'right', fontSize: '90%', marginBottom: '0', marginTop: '20px' }}>
                    Genom att registrera ett konto, godkänner du våra{' '}
                    <a
                      href="https://www.huskompassen.com/terms"
                      target="_blank"
                      rel="noreferrer"
                      title="Läs våra användarvillkor"
                    >
                      användarvillkor{' '}
                      <FontAwesomeIcon icon={faExternalLink} style={{ fontSize: '80%' }}></FontAwesomeIcon>
                    </a>{' '}
                    och vår{' '}
                    <a
                      href="https://www.huskompassen.com/privacy"
                      target="_blank"
                      rel="noreferrer"
                      title="Läs vår integritetspolicy"
                    >
                      integritetspolicy{' '}
                      <FontAwesomeIcon icon={faExternalLink} style={{ fontSize: '80%' }}></FontAwesomeIcon>
                    </a>
                    .
                  </p>
                  <AsyncButton type="submit" isLoading={isLoading} color="success" className="float-right">
                    Registrera
                  </AsyncButton>
                </FormGroup>
              </form>
            </Col>
            <Col sm={5}>
              <Row style={{ height: '100%' }}>
                <Col
                  sm={{ size: 10, offset: 1 }}
                  style={{
                    borderLeft: '10px solid rgb(249, 81, 73)',
                    paddingLeft: '40px',
                    textAlign: 'center',
                  }}
                >
                  <img src={logo} style={{ width: '150px', height: 'auto' }} />
                  <Row>
                    <Col style={{ textAlign: 'left' }}>
                      {usps?.map((usp) => (
                        <Media key={usp.id} className="features pt-4 pb-4">
                          <div className="icon text-center rounded-circle text-primary mr-3 mt-2">
                            <i>
                              <FontAwesomeIcon
                                icon={{ prefix: 'far', iconName: usp.content.icon }}
                                className="icon-ex-md text-primary"
                              />
                            </i>
                          </div>
                          <Media body>
                            <h4 className="title">{usp.content.title}</h4>
                            <p className="text-muted para mb-0">{usp.content.info}</p>
                          </Media>
                        </Media>
                      ))}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default OnboardingNext;
