import { AccountCreationRequest, CompanyProfile, FindAccountRequest, ProfileDetails, User } from '../models';
import { fetchAPI } from './FetchApi';
import { getData } from './useFetch';

const ACCOUNT_BASE_URL = 'v4/account';

export const useAccountDetails = getData<User>(ACCOUNT_BASE_URL);

export const getAccountDetails = () => fetchAPI<User>('GET', ACCOUNT_BASE_URL);

export interface AccountUpdateRequest {
  id: string;
  account: User;
}

export const updateAccountDetails = (account: AccountUpdateRequest): Promise<any> =>
  fetchAPI('PUT', ACCOUNT_BASE_URL, account);

export interface Membership {
  id: string;
  displayName: string;
}

export const getPermissions = () => fetchAPI<Membership[]>('GET', `${ACCOUNT_BASE_URL}/permissions`);

export const isFirstLogon = () => fetchAPI('GET', `${ACCOUNT_BASE_URL}/check`);

export const setAsPrivateUser = (email: string) =>
  fetchAPI('POST', `${ACCOUNT_BASE_URL}/private/${email}/link`);

export const completeProfile = (profile: ProfileDetails) =>
  fetchAPI('POST', `${ACCOUNT_BASE_URL}/complete`, profile);

export const unlinkFromCompany = (userId: string) =>
  fetchAPI('DELETE', `${ACCOUNT_BASE_URL}/business/${userId}/unlink`);

export const linkToCompany = (email: string) =>
  fetchAPI('POST', `${ACCOUNT_BASE_URL}/business/${email}/link`);

export const VerifyExistingCompany = (groupName: string) =>
  fetchAPI('GET', `${ACCOUNT_BASE_URL}/business/${groupName}/exist`);

// Create a new account
export const RegisterAccount = (account: AccountCreationRequest) =>
  fetchAPI('POST', `${ACCOUNT_BASE_URL}/register`, account);

export const FindExistingAccount = (request: FindAccountRequest) =>
  fetchAPI('POST', `${ACCOUNT_BASE_URL}/find`, request);
