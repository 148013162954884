import styled from 'styled-components';

export const SelectableCard = styled.div`
  border: 1px solid #ddd;
  padding: 16px;
  cursor: ${(props) => (props.selected ? 'unset' : 'pointer')};
  position: relative;
  border-radius: 16px;
  transition: all 150ms ease-in-out;
  box-shadow: 0 2px 2px #ddd, inset 0 -50px 50px -50px #ddd;
  box-shadow: ${(props) =>
    props.selected
      ? '0 0px 0px #ddd, inset 0 -25px 25px -25px #000'
      : '0 0px 0px #ddd, inset 0 -50px 50px -50px #ddd'};
  color: ${(props) => (props.selected ? 'white' : '#313131')};
  background: ${(props) => (props.selected ? '#313131!important' : '#fff')};
  top: 0;

  &:hover {
    border-color: #ccc;
    background: ${(props) => (props.selected ? '#313131' : '#fff')};
    box-shadow: ${(props) =>
      props.selected
        ? '0 2px 2px #000, inset 0 -25px 25px -25px #000'
        : '0 4px 8px #ddd, inset 0 -10px 10px -10px #eee'};
    ${(props) => (props.selected ? '#313131' : '#fff')};

    top: ${(props) => (props.selected ? '0' : '-2px')};
    transition: all 200ms ease-in-out;
    transform: ${(props) => (props.selected ? 'scale(1)' : 'scale(1.000)')};
  }
`;
