import { Configuration, UserAgentApplication } from 'msal';
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
const tenant = `${process.env.REACT_APP_AUTH_TENANT_NAME}.onmicrosoft.com`;
const signInPolicy = process.env.REACT_APP_USER_FLOW;
const applicationID = process.env.REACT_APP_AUTH_CLIENT_ID;
const reactRedirectUri = process.env.REACT_APP_NEXTAUTH_URL;
const tenantSubdomain = tenant.split('.')[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
export const signInAuthority = `${instance}${tenant}/${signInPolicy}`;

const signInConfig: Configuration = {
  auth: {
    authority: signInAuthority,
    clientId: applicationID,
    redirectUri: reactRedirectUri,
    validateAuthority: false,
    postLogoutRedirectUri: reactRedirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};
// Authentication Parameters
export const authenticationParameters = {
  scopes: [
    'openid profile offline_access https://huskompassenb2c.onmicrosoft.com/88f5ce76-e6cc-4dfd-bc86-ac6c423ba5dc/Calculation.ReadWrite',
  ],
};
// Options
const options = {
  loginType: LoginType.Redirect,
  //tokenRefreshUri: window.location.origin + '/auth.html',
};

export const signInAuthProvider = new MsalAuthProvider(signInConfig, authenticationParameters, options);
