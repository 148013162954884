import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Suspense } from 'react';

import { LoadingIcon } from './components/Shared/Loading';
import { SelectLicense } from './pages/license/SelectLicense';
import { PurchaseLicense } from './pages/license/PurchaseLicense';
const OrderConfirmation = React.lazy(() => import('./pages/order/confirmation'));
const Onboarding = React.lazy(() => import('./pages/onboarding'));
const NewOnboarding = React.lazy(() => import('./pages/onboarding/new'));
const MyProjects = React.lazy(() => import('./pages/my'));
const Projects = React.lazy(() => import('./pages/projects'));
const Quotes = React.lazy(() => import('./pages/quotes'));
const Account = React.lazy(() => import('./pages/account'));
const SignOut = React.lazy(() => import('./pages/auth/signout'));
const NewProject = React.lazy(() => import('./pages/project/new'));
const EditorSelector = React.lazy(() => import('./pages/project/editorSelector'));
const ProjectPage = React.lazy(() => import('./pages/project/[id]'));
const EditProject = React.lazy(() => import('./pages/project/[id]/edit'));
const Calculation = React.lazy(() => import('./pages/project/[id]/calculation/[calculationId]'));
const SelectedPath = React.lazy(() => import('./pages/project/[id]/path/[pathId]'));
const CalculationDetails = React.lazy(() => import('./pages/project/[id]/calculation/[calculationId]'));
const ManageUsers = React.lazy(() => import('./pages/manage-users'));
const PartnerQuotes = React.lazy(() => import('./pages/partner/quotes'));
const PartnerProfile = React.lazy(() => import('./pages/partner/profile'));
const PartnerLocations = React.lazy(() => import('./pages/partner/locations'));
const PartnerArticles = React.lazy(() => import('./pages/partner/articles'));
const BusinessProfile = React.lazy(() => import('./pages/business-profile'));
const ProjectEditor = React.lazy(() => import('./pages/project/editor'));

export type ProjectParams = { id: string };

export const Routes = () => {
  return (
    <Suspense fallback={<LoadingIcon />}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/projects" />
        </Route>

        <Route path="/account" exact component={Account} />
        <Route path="/business-profile" exact component={BusinessProfile} />

        <Route path="/my" component={MyProjects} />

        <Route path="/project/new" exact component={NewProject} />
        <Route path="/project/new/simple" exact component={NewProject} />
        <Route path="/project/new/advanced" exact component={ProjectEditor} />
        <Route path="/project/editor" exact component={ProjectEditor} />
        <Route path="/project/:id" exact component={ProjectPage} />
        <Route path="/project/:id/edit" exact component={EditProject} />
        <Route path="/project/:id/advanced" exact component={ProjectEditor} />
        <Route path="/project/:id/calculation" exact component={Calculation} />
        <Route path="/project/:id/calculation/:calculationId" exact component={CalculationDetails} />
        <Route path="/project/:id/path/:pathId" exact component={SelectedPath} />
        <Route path="/projects" exact component={Projects} />

        <Route path="/quotes" exact component={Quotes} />

        <Route path="/select-license" exact component={SelectLicense} />
        <Route path="/purchase-license" exact component={PurchaseLicense} />

        <Route path="/register" component={NewOnboarding} />
        <Route path="/onboarding" component={Onboarding} />

        <Route path="/partner/quotes" exact component={PartnerQuotes} />
        <Route path="/partner/profile" exact component={PartnerProfile} />
        <Route path="/partner/locations" exact component={PartnerLocations} />
        <Route path="/partner/articles" exact component={PartnerArticles} />

        <Route path="/manage-users" exact component={ManageUsers} />

        <Route path="/signout" component={SignOut} />

        <Route path="/order/confirmation/:id" exact component={OrderConfirmation} />
      </Switch>
    </Suspense>
  );
};
