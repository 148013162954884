import React, { useEffect, useState } from 'react';
import { Row, Col, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyFormat from 'react-currency-format';
import { licenseConstructionCount } from '../../utils/PriceList';
import { Construction, ConstructionContent, Pricing } from '../../models';
import { SelectableCard } from '../../components/Shared/SelectableCard';
import styled from 'styled-components';

const LicenseCard = styled(SelectableCard)`
  cursor: unset;
  height: 100%;
  &:hover {
    box-shadow: 0 8px 20px #aaa, inset 0 -25px 25px -25px #000;
    top: 0;
  }
`;

interface Props {
  pricing: Pricing;
  constructions: ConstructionContent[];
  //selected: boolean;
  onClick(license: string, constructions: string[]): void;
}

export const License: React.FC<Props> = ({ pricing, constructions, onClick }) => {
  const [selectedConstructions, setSelectedConstructions] = useState<string[]>([]);

  useEffect(() => {
    const preSelected = constructions.slice(0, licenseConstructionCount[pricing.license]).map((x) => x.name);
    setSelectedConstructions(preSelected);
  }, [constructions, pricing]);

  const handleSelectConstructionClicked = (construction: ConstructionContent) => {
    if (selectedConstructions.includes(construction.name)) {
      setSelectedConstructions(selectedConstructions.filter((x) => x !== construction.name));
    } else {
      if (selectedConstructions.filter((x) => x == construction.name).length == 0) {
        let constructions = [...selectedConstructions, construction.name];
        if (constructions.length > licenseConstructionCount[pricing.license]) {
          const start = constructions.length - licenseConstructionCount[pricing.license];
          constructions = constructions.slice(start, constructions.length);
        }

        setSelectedConstructions(constructions);
      } else {
        setSelectedConstructions([construction.name]);
      }
    }
  };

  const getCardCssClasses = (pricing) => {
    let classes = 'mt-1 mb-1 license ';

    if (pricing.isActive) classes += 'bg-light py-2 border-0 rounded';
    else classes += 'bg-light py-2 border-0 rounded';

    return classes;
  };

  return (
    <LicenseCard
      className={getCardCssClasses(pricing)}
      //   onClick={() => onSelectLicense(pricing.license)}
      style={{ minHeight: '300px' }}
    >
      <CardBody
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
      >
        <div>
          <h5 className="title text-uppercase float-left mb-0">{pricing.title}</h5>
          <div className="d-flex justify-content-center mb-3 float-right">
            <span className="price h3 mb-0">
              <CurrencyFormat value={pricing.price} displayType={'text'} thousandSeparator=" " />
            </span>
            <span className="h5 mb-0 mt-2 ml-2">SEK</span>
            <span className="h5 align-self-end mb-1">/{pricing.duration}</span>
          </div>
          <Row style={{ width: '100%' }}>
            <Col xs={12} style={{ fontSize: '85%' }}>
              <ul className="list-unstyled">
                {pricing.features.map((feature, key) => (
                  <li key={key} className={feature.muted ? 'feature-list text-muted' : 'feature-list'}>
                    <i className="ml-2">
                      <FontAwesomeIcon icon={faCheck} className="fea icon-sm text-success mr-2" />
                    </i>
                    {feature.title}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          {pricing.license === 'YearlySubscribers_Premium' && (
            <div>
              <ul className="list-unstyled">
                {/* [...constructions, { id: 'kommande', title: '+ Alla kommande' }].map((construction) => (
                  <li key={construction.name} className="feature-list">
                    <i className="ml-2">
                      <FontAwesomeIcon icon={faCheck} className="fea icon-sm text-success mr-2" />
                    </i>
                    <strong>{construction.title}</strong>
                  </li>
                )) */}
              </ul>
            </div>
          )}
          {pricing.license !== 'YearlySubscribers_Premium' && (
            <FormGroup>
              {constructions?.map((construction) => (
                <Row key={construction.name}>
                  <Col sm={12}>
                    <Input
                      id={pricing.license + '-' + construction.name}
                      type="checkbox"
                      checked={selectedConstructions?.includes(construction.name)}
                      onChange={() => handleSelectConstructionClicked(construction)}
                      style={{ margin: '0 8px' }}
                    />
                    <Label for={pricing.license + '-' + construction.name}>{construction.name}</Label>
                  </Col>
                </Row>
              ))}
            </FormGroup>
          )}
        </div>
        <div>
          <Row
            style={{
              marginTop: '8px',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <button
              className="btn btn-outline-success"
              onClick={() => onClick(pricing.license, selectedConstructions)}
            >
              Välj {pricing.title}
            </button>
          </Row>
        </div>
      </CardBody>
    </LicenseCard>
  );
};
