import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { getPaymentSession } from '../../services/PaymentService';

export const PurchaseLicense = () => {
  const [paymentState, setPaymentState] = useState(null);

  const x = useLocation();
  const klarnaCheckoutContainer = useRef(null);

  const klarnaAsyncCallback = () => {
    console.log('callback yeah!');
  };

  console.log(x.state);
  /*
  useEffect(() => {
    if (!paymentState) {
      getPaymentSession(calculation.id).then((response) => {
        if (response) {
          setPaymentState(response);
        }
      });
    }
  }, [calculation]);
*/
  useEffect(() => {
    if (paymentState) {
      const scriptsTags = klarnaCheckoutContainer.current.getElementsByTagName('script');
      for (let i = 0; i < scriptsTags.length; i++) {
        const parentNode = scriptsTags[i].parentNode;
        const newScriptTag = document.createElement('script');
        newScriptTag.type = 'text/javascript';
        newScriptTag.text = scriptsTags[i].text;
        parentNode.removeChild(scriptsTags[i]);
        parentNode.appendChild(newScriptTag);
      }
    }
  }, [paymentState]);

  return (
    <div>
      <div
        id="klarna-checkout-container"
        ref={klarnaCheckoutContainer}
        dangerouslySetInnerHTML={{
          __html: (paymentState && paymentState.htmlSnippet) || '',
        }}
      ></div>
    </div>
  );
};
