export const getLicenseNiceName = (license) => {
  switch (license) {
    case 'YearlySubscribers_Basic':
      return 'Kom igång';
    case 'YearlySubscribers_Pro':
      return 'Proffs';
    case 'YearlySubscribers_Premium':
      return 'Premium';
    default:
      return 'Okänt';
  }
};

export const getHouseTypeNiceName = (houseType) => {
  switch (houseType) {
    case 'TShapeStructureComponent':
    case 'building-t':
      return 'T-hus';
    case 'HShapeStructureComponent':
    case 'building-h':
      return 'H-hus';
    case 'PlusShapeStructureComponent':
    case 'building-plus':
      return 'Plushus';
    default:
      return 'Rektangel';
  }
};

export const getPathChoiceHouseType = (houseType) => {
  switch (houseType) {
    case 'TShapeStructureComponent':
      return 'building-t';
    case 'HShapeStructureComponent':
      return 'building-h';
    case 'PlusShapeStructureComponent':
      return 'building-plus';
    default:
      return 'building-square';
  }
};

export const getInputNiceName = (name) => {
  switch (name) {
    case 'X1':
      return 'A';
    case 'Y1':
      return 'B';
    case 'X2':
      return 'C';
    case 'Y2':
      return 'D';
    case 'X3':
      return 'E';
    case 'Y3':
      return 'F';
    case 'X4':
      return 'G';
    case 'Y4':
      return 'H';
    case 'X5':
      return 'I';
    case 'Y5':
      return 'J';
    case 'X6':
      return 'K';
    case 'Y6':
      return 'L';
    case 'Angle1':
      return 'Takvinkel T1';
    case 'Angle2':
      return 'Takvinkel T2';
    case 'Angle3':
      return 'Takvinkel T3';
    case 'ProtrusionX':
      return 'Utstick gavel';
    case 'ProtrusionY':
      return 'Utstick takfot';
    case 'Z1':
      return 'Ytterväggshöjd till takfot';
  }
};

export const getInputUnit = (name) => {
  switch (name) {
    case 'X1':
    case 'Y1':
    case 'X2':
    case 'Y2':
    case 'X3':
    case 'Y3':
    case 'X4':
    case 'Y4':
    case 'X5':
    case 'Y5':
    case 'X6':
    case 'Y6':
    case 'ProtrusionX':
    case 'ProtrusionY':
    case 'Z1':
      return 'mm';
    case 'Angle1':
    case 'Angle2':
    case 'Angle3':
      return '°';
  }
};

export const getStatusName = (status) => {
  switch (status) {
    case 2:
      return 'Pågående';
    case 3:
      return 'Väntar';
    case 4:
      return 'Avslutad';
    case 5:
      return 'Stängd';
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case 2:
      return 'primary';
    case 3:
      return 'warning';
    case 4:
      return 'success';
    case 5:
      return 'dark';
  }
};

export const getAddonTitle = (addonType) => {
  switch (addonType) {
    case 1:
      return 'Dörrar';
    case 2:
      return 'Fönster Ö';
    case 3:
      return 'Fönster F';
    case 4:
      return 'Port';
  }
};
