import { AuthenticationParameters } from 'msal';
import { authenticationParameters, signInAuthProvider } from './auth/authProvider';

const params: AuthenticationParameters = {
  scopes: [
    'https://huskompassenb2c.onmicrosoft.com/88f5ce76-e6cc-4dfd-bc86-ac6c423ba5dc/Calculation.ReadWrite',
    'openid',
    'profile',
  ],
};

export const acquireToken = () => {
  return signInAuthProvider
    .acquireTokenSilent(params)
    .then((response) => {
      sessionStorage.setItem('huskompassen.ac.cache', response.accessToken);
      return response.accessToken;
    })
    .catch((error) => {
      if (error && error.errorMessage.includes('AADB2C90077')) {
        signInAuthProvider.acquireTokenRedirect(authenticationParameters);
        return null;
      }
    });
};
