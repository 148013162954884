import { Construction, ConstructionResponse, ConstructionContent } from '../models';
import { fetchAPI } from './FetchApi';
import { getData } from './useFetch';

const CONSTRUCTIONS_BASE_URL = 'v4/constructions';

export const useConstructions = getData<ConstructionContent[]>(CONSTRUCTIONS_BASE_URL);
export const getAllConstructions = () => fetchAPI<ConstructionResponse>('GET', CONSTRUCTIONS_BASE_URL);

export const getConstruction = (id: string) =>
  fetchAPI<Construction>('GET', `${CONSTRUCTIONS_BASE_URL}/${id}`);

export const walkSelections = (paths) => fetchAPI<any>('PUT', CONSTRUCTIONS_BASE_URL, paths);

export const getInputs = (shape) => fetchAPI<any[]>('GET', `${CONSTRUCTIONS_BASE_URL}/${shape}/inputs`);
