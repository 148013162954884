import { fetchAPI } from './FetchApi';

const LICENSE_BASE_URL = 'v4/license';

interface LicenseDetails {
  license: string;
  constructions: string[];
}

export interface License {
  name: string;
  price: number;
  sku: string;
  allowedConstructionsCount: number;
}

export const updateLicense = (userId: string, licenseDetails: LicenseDetails): Promise<any> =>
  fetchAPI('PUT', `${LICENSE_BASE_URL}/${userId}`, licenseDetails);

export const getPriceForLicense = (licenseName: string) =>
  fetchAPI<number>('GET', `${LICENSE_BASE_URL}/${licenseName}`);

export const getLicenses = () => fetchAPI<License[]>('GET', `${LICENSE_BASE_URL}`);
