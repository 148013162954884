export async function retrieveFreshToken(res) {
  if (res && typeof res.writeHead == 'function') {
    res.writeHead(302, { Location: '/api/auth/signin/azureb2c' }).end();
  }
  return null;
}

export function validateRole(role: string, groups: string[]): boolean {
  if (!groups) {
    return false;
  }

  switch (role) {
    case 'company-owner':
      return validateCompanyOwner(groups);
    case 'partner':
      return validatePartner(groups);
  }

  return false;
}

export function validateExplicitRole(role: string, groups: string[] = []) {
  return groups.includes(role);
}

function validateCompanyOwner(groups: string[]): boolean {
  if (!groups.length) return false;

  const owner = groups.filter((x) => x == 'Company Owners');
  const company = groups.filter((x) => x.startsWith('Company_'));

  return Boolean(owner.length && company.length);
}

function validatePartner(groups: string[]): boolean {
  if (!groups.length) return false;

  const partner = groups.filter((x) => x == 'Partners');
  const partnerAdmin = groups.filter((x) => x == 'Partner Admins');

  return Boolean(partner && partnerAdmin);
}

export const hasLicense = (groups: any[] = []): boolean => {
  const licenses = groups.filter((x) => x.startsWith('YearlySubscribers_'));
  if (licenses.length) {
    return licenses[0];
  }

  return false;
};
