import { AuthenticationParameters } from 'msal';
import { authenticationParameters, signInAuthProvider } from '../auth/authProvider';
const apiBaseUrl = process.env.REACT_APP_HUSKOMPASSEN_API_BASE_URI;

type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE';

export async function fetchRawAPI(method: HttpMethod, uri: string, payload = null) {
  return fetch(`${apiBaseUrl}${uri}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('huskompassen.ac.cache')}`,
    },
    body: method === 'POST' || method === 'PUT' ? JSON.stringify(payload) : null,
  }).then((res) => {
    if (res.ok) {
      try {
        if (res.status == 204) return null;
        return res.text();
      } catch {
        // success but not json
        return null;
      }
    } else if (res.status === 401) return null;
    else return null;
  });
}

export async function fetchRaw(method: HttpMethod, uri: string, payload = null) {
  return fetch(`${apiBaseUrl}${uri}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('huskompassen.ac.cache')}`,
    },
    body: method === 'POST' || method === 'PUT' ? JSON.stringify(payload) : null,
  }).then((res) => {
    if (res.ok) {
      try {
        if (res.status == 204) return null;
        return res.blob();
      } catch {
        // success but not json
        return null;
      }
    } else if (res.status === 401) return null;
    else return null;
  });
}

const params: AuthenticationParameters = {
  scopes: [
    'https://huskompassenb2c.onmicrosoft.com/88f5ce76-e6cc-4dfd-bc86-ac6c423ba5dc/Calculation.ReadWrite',
    'openid',
    'profile',
  ],
};

export function fetchAPI<T>(method: HttpMethod, uri: string, payload = null): Promise<T> {
  return fetch(`${apiBaseUrl}${uri}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('huskompassen.ac.cache')}`,
    },
    body: method === 'POST' || method === 'PUT' ? JSON.stringify(payload) : null,
  }).then((res) => {
    if (res.ok) {
      try {
        if (res.status == 204) return true;
        return res.json();
      } catch {
        // success but not json
        return true;
      }
    } else if (res.status === 401) return false;
    else return false;
  });
}
