import { License, Pricing } from '../models';

export const licenseConstructionCount = {
  YearlySubscribers_Basic: 1,
  YearlySubscribers_Pro: 2,
  YearlySubscribers_Premium: 10000,
};

export const needsToSelectedConstructions = (license: License) => {
  switch (license) {
    case 'YearlySubscribers_Basic':
      return true;
    case 'YearlySubscribers_Pro':
      return true;
    case 'YearlySubscribers_Premium':
      return false;
    default:
      return false;
  }
};

export const pricings: Pricing[] = [
  {
    id: 1,
    title: 'Kom igång',
    price: 2495,
    duration: 'år ex. moms',
    buttonText: 'Köp nu',
    btnLink: '',
    license: 'YearlySubscribers_Basic',
    features: [
      { title: 'En användare', muted: false },
      { title: 'Obegränsat antal beräkningar', muted: false },
      { title: 'Mobilapp', muted: false },
      { title: 'Webbaserat verktyg', muted: false },
      { title: 'Byt moment upp till två ggr per år', muted: false },
      { title: 'Ett byggmoment:', muted: false },
    ],
  },
  {
    id: 2,
    title: 'Proffs',
    price: 4995,
    duration: 'år ex. moms',
    buttonText: 'Köp nu',
    btnLink: '',
    isActive: true,
    license: 'YearlySubscribers_Pro',
    features: [
      { title: 'En användare', muted: true },
      { title: 'Obegränsat antal beräkningar', muted: true },
      { title: 'Mobilapp', muted: true },
      { title: 'Webbaserat verktyg', muted: true },
      { title: 'Byt moment upp till två ggr per år', muted: true },
      { title: 'Två byggmoment:', muted: false },
    ],
  },
  {
    id: 3,
    title: 'Premium',
    price: 7995,
    duration: 'år ex. moms',
    buttonText: 'Köp nu',
    btnLink: '',
    license: 'YearlySubscribers_Premium',
    features: [
      { title: 'En användare', muted: true },
      { title: 'Obegränsat antal beräkningar', muted: true },
      { title: 'Mobilapp', muted: true },
      { title: 'Webbaserat verktyg', muted: true },
      { title: 'Alla byggmoment, inkl. kommande', muted: false },
    ],
  },
];
