import { useState, useEffect, useRef, useCallback } from 'react';
import { fetchAPI } from './FetchApi';

interface Options<T> {
  defaultValue?: T;
  map?: (data: any) => any;
}

export function getData<T>(url: string): (options?: Options<T>) => [T, boolean, any, () => Promise<void>] {
  const isMounted = { state: true };
  return (options: Options<T> = {}) => {
    const cache = useRef({});
    const [data, setData] = useState<T | undefined>(options.defaultValue);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = useCallback(
      async (clearCache = true) => {
        if (clearCache) {
          console.log('clearing cache');
          (cache.current as any)[url] = null;
        }
        if ((cache.current as any)[url]) {
          console.log('getting data from cache');
          const dataFromCache = (cache.current as any)[url];
          setData(dataFromCache);
          setIsLoading(false);
        } else {
          console.log('fetching new data:', url);
          try {
            isMounted.state = true;
            fetchAPI<T>('GET', url)
              .then((newData) => (options.map ? options.map(newData) : newData))
              .then((newData) => {
                if (newData && isMounted.state) {
                  setData(newData);
                  (cache.current as any)[url] = newData;
                }
                if (!newData && isMounted.state) {
                  setError('no data');
                }
              })
              .catch((error) => {
                setError(error);
                setIsLoading(false);
              })
              .finally(() => {
                if (isLoading) {
                  setIsLoading(false);
                }
              });
          } catch (err) {
            setError(err);
            setIsLoading(false);
          }
        }
      },
      [isLoading, options],
    );

    useEffect(() => {
      if (!url) return;

      fetchData(false);
      return () => {
        isMounted.state = false;
        // clear cache on route change
        (cache.current as any)[url] = null;
      };
    }, [url]);

    return [data, isLoading, error, fetchData] as any;
  };
}
