import { Row, Col, Spinner } from 'reactstrap';

export const LoadingIcon = () => {
  return (
    <Row>
      <Col className="text-center">
        <Spinner color="primary" />
      </Col>
    </Row>
  );
};
