import { useEffect, useState } from 'react';
import { signInAuthProvider } from './auth/authProvider';
import { User } from './models';
import { getAccountDetails, getPermissions } from './services/AccountService';
import { acquireToken } from './acquireToken';

export interface Session {
  accessToken?: string;
  businessName?: string;
  expires?: Date;
  groups?: string[];
  user?: SessionUser;
  userId?: string;
}

interface SessionUser {
  name: string;
  email: string;
  image: null | string;
}

type UserWithGroups = User & { groups: string[] };

export const useSession = () => {
  const [user, setUser] = useState<UserWithGroups | null>(null);

  const updateUser = () => {
    Promise.all([getAccountDetails(), getPermissions()])
      .then(([user, groups]) => {
        if (!user) return;
        return {
          ...user,
          name: user.firstName + ' ' + user.lastName,
          groups: groups.map((x) => x.displayName),
          id: signInAuthProvider.getAccountInfo().account.accountIdentifier,
        };
      })
      .then(setUser);
  };

  useEffect(() => {
    acquireToken().then((token) => {
      if (token === null) return;

      updateUser();
    });
  }, []);

  return [user, updateUser] as const;
};
