export interface BreadCrumb {
  id: number;
  name: string;
  link: string;
  onClick?: () => void;
}

export interface PagedResponse<T> {
  data: { [id: string]: T };
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}
export interface BoughtConstruction {
  boughtAt: Date;
  calculation: string;
  constructionName: string;
  name: string;
  createdAt: Date;
  id: string;
}

export interface Calculation {
  construction?: string;
  constructionName: string;
  calculationName: string;
  input: Input;
  name: string;
  pathChoices: PathChoice[];
  projectId: string;
  selectedConstructions: string[];
  userId: string;
  createdAt: Date;
  id: string;
  details: any[];
  boughtAt: Date;
}

interface PathChoice {
  componentKey: string;
  value: HouseType;
}
export interface Project {
  id?: string;
  createdAt?: Date;
  inputs: any;
  name: string;
  timeStamp?: Date;
  updatedAt?: Date | null;
  houseType?: HouseType;
  addons: Addon[];
  model?: HuskompassenModel;
  hasModel?: boolean;
}

export interface Addon {
  id: string;
  type: number;
  width: number;
  height: number;
  amount: number;
}

export interface User {
  id?: string;
  name?: string;
  displayName?: string;
  constructions?: any[];
  accountType: AccountType;
  antalAnstallda: string;
  businessName: string;
  emailAddress: string;
  firstName: string;
  bransch: string;
  lastName: string;
  license: string;
  licenseExpiresAt: Date;
  licensedConstructionLastChangedAt: Date;
  licensedConstructions: any[];
  marknadsmaterial: boolean;
  phoneNumber: string;
}

export enum AccountType {
  Undefined = 0,
  Private = 1,
  Company = 2,
}

export interface ConstructionResponse {
  count: number;
  data: Construction[];
}

export interface Construction {
  id: string;
  title: string;
  description: string;
  inputs?: Input[];
  paths?: Path[];
  properties?: never[];
  isBeta: boolean;
  disabled?: boolean;
  conditions?: Condition[];
}

export interface ConstructionContent {
  beta: boolean;
  name: string;
  title: string;
  description: any;
  materials?: any;
  requiresPremium: string;
}

export interface Condition {
  field?: string;
  compare?: 'AtLeastOne' | 'MoreThanOne' | 'Equal' | 'NotEqual';
  value?: any;
  closedBuilding?: boolean;
  warningMessage: string;
}

interface Path {
  type: 'Parameter' | 'Path' | number;
  title: string;
  name: string;
  description: string;
  unit: string;
  value: string;
  values: any;
  isRequired: boolean;
}

interface PathSelection {
  componentKey: string;
  selections: Selection[];
}

interface Selection {
  idf: string;
  title: string;
}

export type HouseType =
  | 'building-square'
  | 'building-t'
  | 'building-plus'
  | 'building-h'
  | 'TShapeStructureComponent'
  | 'HShapeStructureComponent'
  | 'PlusShapeStructureComponent'
  | 'SquareStructureComponent';

export type Input =
  | 'X1'
  | 'Y1'
  | 'X2'
  | 'Y2'
  | 'X3'
  | 'Y3'
  | 'X4'
  | 'Y4'
  | 'X5'
  | 'Y5'
  | 'X6'
  | 'Y6'
  | 'X7'
  | 'Angle1'
  | 'Angle2'
  | 'Angle3'
  | 'ProtrusionX'
  | 'ProtrusionY'
  | 'Utstick takfot'
  | 'Z1';

export type License = 'YearlySubscribers_Basic' | 'YearlySubscribers_Pro' | 'YearlySubscribers_Premium';

export interface Calculation {
  calculation: Foo[];
}

export interface MaterialSpecification {
  id: string;
  price: number;
  projectName: string;
  calculationName: string;
  constructionName: string;
  boughtAt?: Date;
  calculation: CalculationResponse;
  details: string[];
  inputs: CalculationInput[];
}

export interface CalculationResponse {
  material: CategorizedCalculationResult[];
  cutting: any;
  summarized: CategorizedCalculationResult[];
  outputs: OutputItem[];
  inputs?: CalculationInput[];
}

export interface CategorizedCalculationResult {
  title: string;
  cutting: any;
  items: CategorizedCalculationItem[];
}

export interface CategorizedCalculationItem {
  id?: string;
  title: string;
  materialTitle?: string;
  description: string;
  amount: number;
  width: number;
  height: number;
  length: number;
  unit: string;
  materialUnit?: string;
  materialSalesTitle?: string;
  order: number;
  items: CategorizedCalculationItem[];
  materialSpecification: string;
  variant: string;
}

interface MaterialCalculationResult {
  id: string;
  price: number;
  materials: any;
  result: any;
}

export interface CategorizedCalculation {
  id: string;
  projectName: string;
  calculationName: string;
  constructionName: string;
  categories: CalculationCategory[];
  output: OutputItem[];
  inputs: CalculationInput[];
}

interface CalculationInput {
  name: Input;
  title: string;
  unit: 'mm';
  value: number;
}

export interface CalculationCategory {
  title: string;
  items: CalculationItem[];
}

export interface CalculationItem {
  title: string;
  description: string;
  order: number;
  amount: number;
  variant: string;
  unit: string;
  width: number;
  height: number;
  length: number;
  items: CalculationItem[];
  materialSpecification: string;
}

export interface OutputItem {
  group?: string;
  title: string;
  value: number;
  unit: string;
}

interface Foo {
  materials: any;
}

export interface Pricing {
  id: number;
  title: string;
  price: any;
  duration: string;
  buttonText: string;
  btnLink: string;
  isActive?: boolean;
  license: string;
  features: PricingFeature[];
}

interface PricingFeature {
  title: string;
  muted: boolean;
}

export interface QuoteListItem {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt?: Date;
  status: number;
  priority: number;
}

export interface Quote {
  id: number;
  name: string;
  createdAt: string;
  updatedAt?: string;
  responsibleLocation?: string;
  emailAddress: string;
  phone: string;
  status: number;
  daysSinceLastUpdate: number;
  user: QuoteUser;
  conversations: QuoteConversation[];
  calculation: QuoteCalculation;
  partner: Partner;
}

export interface QuoteUser {
  name?: string;
  phone?: string;
  emailAddress: string;
}

export interface QuoteCalculation {
  id: string;
  name: string;
  constructionName: string;
}

export interface QuoteConversation {
  id: number;
  incoming: boolean;
  body: string;
  createdAt: string;
  updatedAt?: string;
  from: string;
  attachments: QuoteAttachment[];
}

export interface QuoteAttachment {
  id: number;
  name: string;
  size: number;
  contentType: string;
  url: string;
}

export interface Partner {
  id: string;
  name: string;
  contactMail: string;
  contactPhone: string;
  description: string;
  distance: number;
  latitude: number;
  longitude: number;
  logoUrl: string;
  locations: PartnerLocation[];
  onlyWithinSpecificRange?: boolean;
  range?: number;
}

export interface PartnerLocation {
  id: string;
  name: string;
  kindOfStore: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  distance?: number;
  contactPhone: string;
  contactMail: string;
  onlyWithinSpecificRange?: boolean;
  range?: number;
}

export interface PartnerArticle {
  id?: string;
  name: string;
  description: string;
  width: number;
  coverWidth: number;
  thickness: number;
  lengths: number[];
  productImageUrl: string;
  sku: string;
  partnerSku: string;
  unit: string;
  variant: string;
  isVisible: boolean;
  isApproved?: boolean;
  autoPickOnPartnerSelect?: boolean;
  partnerId?: string;
  partnerName?: string;
  partnerEmail?: string;
  partnerPhone?: string;
}

export interface CompanyProfile {
  addressLine1: string;
  addressLine2: string;
  city: string;
  zip: string;
  id: string;
  invoiceRef: string;
  lastInvoicedAt?: Date;
  members?: CompanyMember[];
  name: string;
  orgNr?: string;
  email?: string;
  owner: CompanyMember;
  partnerId: string;
  postalCode: string;
}

export interface CompanyMember {
  constructions?: any;
  displayName: string;
  emailAddress: string;
  id: string;
  license?: any;
}

export interface ProfileDetails {
  user: Partial<User>;
  shouldCreateBusiness: boolean;
  business: Partial<CompanyProfile>;
  license: LicenseDetails;
}

export interface LicenseDetails {
  license: string;
  constructions: string[];
}

export interface Vector2 {
  x: number;
  y: number;
}

export enum AddonType {
  None,
  Door,
  WindowOpenable,
  WindowFixed,
  WindowDoor,
  Removal,
  Gutter,
}

export enum AddonSide {
  None,
  Top,
  Right,
  Bottom,
  Left,
}

export interface AddonDefinition {
  type: 'None' | 'Door' | 'WindowOpenable' | 'WindowFixed' | 'WindowDoor' | 'Removal' | 'Gutter';
  module: Vector2;
  top: number;
  position: number;
  side?: 'None' | 'Top' | 'Right' | 'Bottom' | 'Left';
  wallRef?: number;
  originalRef?: number;
}

export interface WallPoint {
  key: number;
  value: Vector2;
}

export enum WallAttributes {
  None,
  Normal,
  Ryggas,
}

export interface WallDefinition {
  from: number;
  to: number;
  type: WallType;
  attributes?: WallAttributes;
  overhang: number;
  height: number;
  addons?: AddonDefinition[];

  // this is not part of the json
  length?: number;
  direction?: number;
  label?: string;
}

export type WallType = 'Gable, NonStructuralWall' | 'Eaves, StructuralWall';

export enum RoofAttributes {
  None = 0,
  Sadeltak = 2,
  Mansardtak = 4,
  Valmattak = 8,
  Pulpettak = 16,
  Motfallstak = 32,
  HasTwoGables = 64,
  HasOneGable = 128,
  HasNoGable = 256,
  HasGutter = 512,
}

export interface RoofDefinition {
  connectingPoints: number[];
  attributes?:
    | 'Sadeltak'
    | 'Sadeltak, HasTwoGables'
    | 'Sadeltak, HasOneGable'
    | 'Sadeltak, HasNoGable'
    | 'Pulpettak';
  angles: number[];
  sectionLenghts: number[];
  label?: string;
}

export interface HuskompassenModel {
  name?: string;
  wallPoints: HuskompassenWallPoints;
  wallDefinitions: WallDefinition[];
  roofDefinitions: RoofDefinition[];
}

export type HuskompassenWallPoints = {
  [key: number]: HuskompassenWallPoint;
};

export interface HuskompassenWallPoint {
  x: number;
  y: number;
}

export interface Point {
  from: number;
  to: number;
}

export interface DrawingRequest {
  model: HuskompassenModel;
  highlightType: 'Line' | 'None' | 'Roof';
  highlightPoint: Point;
  layers: string[];
}

export interface DrawingVector {
  X: number;
  Y: number;
}

export interface DrawingObject {
  key: string;
  type: 'Line' | 'Text' | 'Ruler' | 'Rect' | 'Addon' | 'Arc';
  os: DrawingVector;
  s: DrawingVector;
  e: DrawingVector;
  c: string;
  bc: string;
  t: number | string;
  th: number;
  si: number;
  a: boolean;
  sa?: number;
  ea?: number;
  r: number;
  p: DrawingVector;
}

export interface DrawingResponse {
  w: number;
  h: number;
  d: DrawingObject[];
}

export interface AccountCreationRequest {
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
  type: 'private' | 'corporate';
  companyId: string;
  companyName: string;
  paymentMethod: 'none' | 'card' | 'invoice';
  license: string;
  licensedConstructions: string[];
  invoiceAddress: {
    line1: string;
    line2?: string;
    postalCode: string;
    city: string;
    country: 'Sverige';
  };
}

export interface FindAccountRequest {
  emailAddress: string;
}

export interface FindCompanyRequest {
  companyName: string;
}
