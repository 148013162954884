import { CompanyProfile, FindCompanyRequest } from '../models';
import { fetchAPI } from './FetchApi';
import { getData } from './useFetch';

const COMPANY_BASE_URL = 'v4/company';

export const useCompanyProfile = getData<CompanyProfile>(`${COMPANY_BASE_URL}`);

export const getCompanyProfile = () => fetchAPI('GET', `${COMPANY_BASE_URL}`);

export const FindExistingCompany = (request: FindCompanyRequest) =>
  fetchAPI('POST', `${COMPANY_BASE_URL}/find`, request);

export const updateCompanyProfile = (profile: CompanyProfile) =>
  fetchAPI('PUT', `${COMPANY_BASE_URL}`, { company: profile });
