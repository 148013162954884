import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AzureAD, { AuthenticationState, IAzureADFunctionProps } from 'react-aad-msal';
import { signInAuthProvider } from './auth/authProvider';
import { Spinner } from 'reactstrap';
import { HashRouter as Router } from 'react-router-dom';
import Layout from './components/Layout';

import './assets/css/sidebar.css';
import './App.scss';
import './assets/css/materialdesignicons.min.css';
import { NotificationsProvider } from './components/Notifications/NotificationProvider';
import { Routes } from './routes';
import OnboardingNext from './pages/onboarding/new';

if (window.location.hash.indexOf('/register') > -1) {
  ReactDOM.render(<OnboardingNext />, document.getElementById('root'));
} else {
  ReactDOM.render(
    <AzureAD provider={signInAuthProvider} forceLogin={true}>
      {({ authenticationState, accountInfo, error }: IAzureADFunctionProps) => {
        if (authenticationState === AuthenticationState.Unauthenticated) {
          if (error?.errorMessage.startsWith('AADB2C90091')) {
            signInAuthProvider.authority = `https://${process.env.REACT_APP_AUTH_TENANT_NAME}.b2clogin.com/tfp/${process.env.REACT_APP_AUTH_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_USER_FLOW}`;
            signInAuthProvider.loginRedirect();
            return null;
          } else if (error?.errorMessage.startsWith('AADB2C90118')) {
            signInAuthProvider.authority = `https://${process.env.REACT_APP_AUTH_TENANT_NAME}.b2clogin.com/tfp/${process.env.REACT_APP_AUTH_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_PASSWORD_RESET_FLOW}`;
            signInAuthProvider.loginRedirect();
            return null;
          }

          return (
            <div className="d-flex justify-content-center" style={{ width: '100%', height: '100vh' }}>
              <div className="align-self-center text-center">
                <Spinner color="primary" style={{ width: '100px', height: '100px' }} />
                <br />
                <h3 className="d-block mt-4">Laddar...</h3>
              </div>
            </div>
          );
        }
        if (authenticationState === AuthenticationState.InProgress) {
          return (
            <div className="d-flex justify-content-center" style={{ width: '100%', height: '100vh' }}>
              <div className="align-self-center text-center">
                <Spinner color="primary" style={{ width: '100px', height: '100px' }} />
                <h3 className="d-block mt-4">Autentiserar</h3>
              </div>
            </div>
          );
        }
        if (authenticationState === AuthenticationState.Authenticated) {
          return (
            <Router>
              <Layout>
                <NotificationsProvider>
                  <Routes />
                </NotificationsProvider>
              </Layout>
            </Router>
          );
        }
      }}
    </AzureAD>,
    document.getElementById('root'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
