import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Badge } from 'reactstrap';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { getLicenseNiceName } from '../../utils/InputTranslator';
import { hasLicense, validateRole } from '../../utils/AuthValidator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { useUser } from '../../auth/authUserContext';
import { signInAuthProvider } from '../../auth/authProvider';
import { AccountType } from '../../models';

interface Props {
  sideBarOpen: boolean;
  toggleSideBar: () => void;
}

interface Account {
  name?: string;
  businessName?: string;
  isCompanyOwner?: boolean;
}

const Topbar: React.FC<Props> = ({ sideBarOpen, toggleSideBar }) => {
  const user = useUser();
  const [license, setLicense] = useState(false);
  const [account, setAccount] = useState<Account>({});
  const { businessName, isCompanyOwner, name } = account;

  const hasActiveLicense = license;

  useEffect(() => {
    if (!user) return;

    setLicense(hasLicense(user.groups));
    setAccount({
      name: user.name,
      businessName: user.businessName,
      isCompanyOwner: validateRole('company-owner', user.groups),
    });
  }, [user]);

  // if (businessName == null) {
  //   sideBarOpen = false;
  // } else {
  //   sideBarOpen = true;
  // }
  console.log({ isCompanyOwner });
  const [isProfileMenuOpen] = useState(false);

  return (
    <header
      id="topnav"
      className={classNames('defaultscroll', 'sticky', 'nav-sticky', 'pl-4', 'pr-4', {
        'topnav-with-open-sidebar': sideBarOpen,
      })}
    >
      <div
        id="toptoolbar"
        style={{ position: 'absolute', marginLeft: '50px', left: '20px', top: '6px', width: '100%' }}
      ></div>
      <div className="profile-menu">
        <ul className="navigation-menu d-none d-lg-inline" id="top-menu">
          <li className="has-submenu">
            <a onClick={() => false}>
              <FontAwesomeIcon icon={faUser} className="mr-2 text-dark" />
              {user.firstName ? name : 'Inget namn ännu'}
            </a>
            <span className="menu-arrow"></span>
            {user.accountType == AccountType.Undefined && (
              <Alert
                color="warning text-left"
                style={{
                  position: 'absolute',
                  top: '47px',
                  right: -4,
                  left: 12,
                  fontSize: '10px',
                  padding: '3px 10px',
                }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                Färdigställ din profil
              </Alert>
            )}

            <ul className={isProfileMenuOpen ? 'submenu open' : 'submenu'}>
              <li className="pl-3 pr-3" style={{ fontSize: '90%' }}>
                {user.accountType == AccountType.Undefined && (
                  <span className="mb-2">
                    <strong>Kontotyp</strong>
                    <br />

                    <Link to="/onboarding/business-profile" style={{ padding: 0 }}>
                      <Badge color="warning" className="mb-2">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                        <span>Välj kontotyp</span>
                      </Badge>
                    </Link>
                    <br />
                  </span>
                )}
                {user.accountType == AccountType.Private && (
                  <span className="mb-2">
                    <strong>Kontotyp</strong>
                    <br />

                    <Link to="/onboarding/business-profile" style={{ padding: 0 }}>
                      <Badge color="success" className="mb-2">
                        <span>Välj kontotyp</span>
                      </Badge>
                    </Link>
                    <br />
                  </span>
                )}
                {user.accountType == AccountType.Company && (
                  <span className="mb-2">
                    <strong>LICENS</strong>
                    <br />
                    {hasActiveLicense ? (
                      <Badge color="success" className="mb-2">
                        {getLicenseNiceName(license)}
                      </Badge>
                    ) : (
                      <Link to="/select-license" style={{ padding: 0 }}>
                        <Badge color="warning" className="mb-2">
                          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                          <span>Välj licens</span>
                        </Badge>
                      </Link>
                    )}
                    <br />
                  </span>
                )}
                {user.accountType == AccountType.Company && account.businessName && (
                  <>
                    <span className="mt-2">
                      <strong>FÖRETAG</strong>
                    </span>
                    <br />
                    <span>{account.businessName}</span>
                  </>
                )}
              </li>
              <li>
                <hr />
              </li>
              <li>
                <Link to="/account">Mitt konto</Link>
              </li>
              <li>
                <a onClick={signInAuthProvider.logout} style={{ cursor: 'pointer' }}>
                  Logga ut
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      {user.accountType == AccountType.Company && (
        <div className="profile-menu">
          <ul className="navigation-menu d-none d-lg-inline" id="top-menu">
            <li className="has-submenu">
              <a onClick={() => false}>
                <FontAwesomeIcon icon={faBuilding} className="mr-2 text-dark" />
                {businessName && businessName.length ? businessName : 'Tillhör inget företag ännu.'}
              </a>
              {isCompanyOwner && (
                <>
                  <span className="menu-arrow"></span>
                  <ul className={isProfileMenuOpen ? 'submenu open' : 'submenu'}>
                    <li>
                      <Link to="/manage-users">Hantera användare</Link>
                    </li>
                    <li>
                      <Link to="/business-profile">Företagsuppgifter</Link>
                    </li>
                  </ul>
                </>
              )}
            </li>
          </ul>
        </div>
      )}
      <div className="menu-extras">
        <div className="menu-item">
          <span onClick={toggleSideBar} className={sideBarOpen ? 'navbar-toggle open' : 'navbar-toggle'}>
            <FeatherIcon icon="menu" />
          </span>
        </div>
      </div>
      <div id="navigation"></div>
    </header>
  );
};

export default Topbar;
