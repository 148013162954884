import React from "react";
import { AlertDispatchContext, AlertStateContext } from "./NotificationContext";
import { notificationsReducer } from "./NotificationState";

type Props = { children: React.ReactNode };

export function NotificationsProvider({ children }: Props) {
  const [state, dispatch] = React.useReducer(notificationsReducer, {
    notifications: [],
  });
  return (
    <AlertStateContext.Provider value={state}>
      <AlertDispatchContext.Provider value={dispatch}>
        {children}
      </AlertDispatchContext.Provider>
    </AlertStateContext.Provider>
  );
}
