import React from 'react';
import { NavItem, Nav } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledButton } from '../Form/Button';
import { faCogs, faPlus, faSignOut, faStar, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { validateExplicitRole, validateRole } from '../../utils/AuthValidator';
import {
  faArchive,
  faBriefcase,
  faCabinetFiling,
  faFileUser,
  faHome,
  faMapMarkerEdit,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import logo from '../../assets/images/logo.png';
import { signInAuthProvider } from '../../auth/authProvider';
import { useUser } from '../../auth/authUserContext';

interface Props {
  isOpen: boolean;
}

const navItems = [
  {
    title: 'Beräkningar',
    subItems: [
      { title: 'Skapa nytt projekt', href: '/project/new', icon: faPlus },
      { title: 'Mina projekt', href: '/projects', icon: faHome },
      { title: 'Beräkningsarkiv', href: '/my', icon: faArchive },
    ],
  },
  // {
  //   title: 'Offerter',
  //   subItems: [
  //     {
  //       title: 'Mina offertförfrågningar',
  //       href: '/quotes',
  //       icon: faBriefcase,
  //     },
  //   ],
  // },
  {
    title: 'Partner',
    roles: ['Partners'],
    subItems: [
      {
        title: 'Våra offerter',
        href: '/partner/quotes',
        icon: faBriefcase,
        roles: ['Partners'],
      },
      {
        title: 'Partnerprofil',
        href: '/partner/profile',
        icon: faFileUser,
        roles: ['Partner Admins'],
      },
      {
        title: 'Filialer',
        href: '/partner/locations',
        icon: faMapMarkerEdit,
        roles: ['Partner Admins'],
      },
      {
        title: 'Artikelmappning',
        href: '/partner/articles',
        icon: faCabinetFiling,
        roles: ['Partner Admins'],
      },
      {
        title: 'Bli partner till oss!',
        href: '/partner/signup',
        icon: faStar,
        roles: ['!Partners'], // not have the role Partners
      },
    ],
  },
  {
    title: 'Administration',
    roles: ['Huskompassen Admins'],
    subItems: [
      {
        title: 'Väntande artiklar',
        href: '/admin/articles',
        icon: faCogs,
        roles: ['Huskompassen Admins'],
      },
    ],
  },
  {
    title: 'Företag',
    roles: ['Company Owners'],
    subItems: [
      { title: 'Hantera användare', href: '/manage-users', icon: faUsers },
      {
        title: 'Företagsuppgifter',
        href: '/business-profile',
        icon: faBuilding,
      },
    ],
  },
  {
    title: 'Användare',
    subItems: [
      { title: 'Hantera mitt konto', href: '/account', icon: faUser },
      { title: 'Logga ut', href: '/signout', icon: faSignOut },
    ],
  },
];

const SideBar: React.FC<Props> = ({ isOpen }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const user = useUser();

  const iconStyles = {
    width: '20px',
    color: '#333',
  };

  const onCreateNewProject = () => {
    history.push('/project/new');
  };

  const hasRole = (item) => {
    if (typeof item.roles == 'undefined') {
      return true;
    }

    let hasRole = false;

    item.roles.forEach((role) => {
      const inverted = role[0] == '!';
      const valid = validateRole(role, user.groups) || validateExplicitRole(role, user.groups);
      if (inverted) {
        if (valid) hasRole = false;
      } else {
        hasRole = valid;
      }
    });

    return hasRole;
  };

  const isNewUser = !user.firstName;

  const MenuItem = ({ subItem, isActive }) => (
    <NavItem
      style={isActive ? { background: '#fff', color: '#333' } : { color: '#555', fontWeight: 500 }}
      key={subItem.href}
      className={isActive ? 'menu-item menu-item-active' : 'menu-item'}
    >
      <span
        onClick={subItem.href == '/signout' ? signInAuthProvider.logout : () => history.push(subItem.href)}
        className="nav-link"
        style={{ cursor: 'pointer' }}
      >
        {subItem.icon && (
          <i className={isActive ? 'menu-icon-active' : ''}>
            <FontAwesomeIcon icon={subItem.icon} className="icon-ex-md mr-2" style={iconStyles} />
          </i>
        )}
        {subItem.title}
      </span>
    </NavItem>
  );

  return (
    <div className={classNames('page-sidebar', { 'is-open': isOpen })}>
      <div className="sidebar-header">
        <img src={logo} style={{ width: '40px', height: '40px' }} />
        <span>Huskompassen</span>
      </div>
      <div className="side-menu">
        {!isNewUser && (
          <>
            {navItems.filter(hasRole).map((navItem, index) => (
              <Nav vertical key={index} className="list-unstyled pb-3">
                <div className="menu-title">{navItem.title}</div>
                {navItem.subItems.filter(hasRole).map((subItem) => (
                  <MenuItem key={subItem.href} isActive={subItem.href === pathname} subItem={subItem} />
                ))}
              </Nav>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SideBar;
