import React from 'react';
import { User } from '../models';

type UserWithGroups = User & { groups: string[] };

const UserContext = React.createContext<UserWithGroups>(null);

function useUser(): UserWithGroups {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
}

function UserProvider(props) {
  const [user, setUser] = React.useState<UserWithGroups>(null);

  const value = React.useMemo(() => [user, setUser], [user]);

  return <UserContext.Provider value={value} {...props} />;
}
export { UserProvider, useUser };
