import React from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Button, Spinner } from 'reactstrap';

export const StyledButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  color,
  style,
  className,
  title,
  onClick,
}) => {
  return (
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
      <button
        style={style}
        title={title}
        className={classNames('btn', `btn-${color}`, className)}
        onClick={onClick}
      >
        {children}
      </button>
    </motion.div>
  );
};

interface AsyncButtonProps {
  isLoading: boolean;
  title?: string;
  color: string;
  spinColor?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  className?: string;
  style?: any;
  type?: any;
  id?: string;
}

export const AsyncButton: React.FC<AsyncButtonProps> = ({
  isLoading,
  children,
  title,
  color,
  spinColor,
  style,
  className,
  onClick,
  disabled,
  id,
  type = 'button',
}) => {
  if (!isLoading) {
    return (
      <Button
        id={id}
        color={color}
        style={style}
        className={className}
        onClick={onClick}
        title={title}
        disabled={disabled}
        type={type}
      >
        {children}
      </Button>
    );
  } else {
    return (
      <div className={classNames('float-right text-center mt-2', className)} style={{ width: '94px' }}>
        <Spinner color={spinColor ? spinColor : color} />
      </div>
    );
  }
};
